import moment from 'moment'
import router from '../router'
import CryptoJS from "crypto-js"

export function decrypted(str, defaultKey) {
  let key = CryptoJS.enc.Utf8.parse(defaultKey);
  //  let  iv = CryptoJS.enc.Utf8.parse(defaultKey);
  // DES 加密
  var encrypted = CryptoJS.DES.encrypt(str, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  // 转换为字符串
  let str1 = encrypted.ciphertext.toString();
  // let str1 = encrypted.toString();

  return str1
}
export function unEncryptByAES(str, defaultKey) {
  let key = CryptoJS.enc.Utf8.parse(defaultKey);
  // let iv = CryptoJS.enc.Utf8.parse(defaultKey);
  var decrypted = CryptoJS.DES.decrypt({
    ciphertext: CryptoJS.enc.Hex.parse(str)
  }, key, {
    // iv: iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  //   var decrypted = CryptoJS.DES.decrypt(str, key, {
  //     // iv: iv,
  //     mode: CryptoJS.mode.ECB,
  //     padding: CryptoJS.pad.Pkcs7
  // });

  // 转换为 utf8 字符串
  let str2 = CryptoJS.enc.Utf8.stringify(decrypted);
  return str2
}
moment.locale('zh-cn')
// 根据分钟数优化显示
export function getLongTime(val) {
  if (val) {
    let d = Math.floor(val / (24 * 60))
    let h = Math.floor(val % (24 * 60) / 60)
    let minute = Math.floor(val % 60)
    let longTimes = `${d>0?`${d}天`:''}${h>0?`${h}小时`:''}${minute>0?`${minute}分钟`:''}`
    if (longTimes) return longTimes
    return Math.floor(val * 60) + '秒'
  } else {
    return val
  }
}
// 根据秒转时分秒
export function secondGetLongTime(val) {
  if (val) {
    let d = Math.floor(val / (24 * 60 * 60)),
      h = Math.floor((val / (60 * 60)) % 24),
      minute = Math.floor((val / 60) % 60),
      s = Math.floor(val % 60)
    let longTimes = `${d>0?`${d}天`:''}${h>0?`${h}小时`:''}${minute>0?`${minute}分钟`:''}${s>0?`${s}秒`:''}`
    return longTimes
  } else {
    return val
  }
}
// 根据开始时间结束时间优化显示时长
export function getLongDateTime(start, end) {
  if (start == end) return '0分钟'
  var startTimes = new Date(start).getTime(); //开始时间  
  var endTimes = end ? new Date(end).getTime() : new Date().getTime(); //结束时间  
  var longTimes = getLongTime((endTimes - startTimes) / 1000 / 60); //时间差的毫秒数  
  return longTimes
}
export function showLabel(val, list, type) {
  let text = ''
  if (list) {
    try {
      list.forEach(v => {
        if (val == v.value) {
          text = type == 'icon' ? v : v.label
          throw new Error(v.label)
        }
      })
    } catch (error) {}
  }
  return text
}




export function showDayTimeVal(val) {
  if (val) {
    return `${val.substring(0,4)}-${val.substring(4,6)}-${val.substring(6,8)} ${val.substring(8,10)}:${val.substring(10,12)}:${val.substring(12,14)}`
  }
  return val
}

export function labelShowDate(val) {
  if (val) {
    let d = new Date(val);
    return d.toLocaleDateString().split("/").join('-')
  }
  return val
}
export function getDay(day) {
  let d = day ? day : 0
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * d;
  today.setTime(targetday_milliseconds); //注意，这行是关键代码
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
}

function doHandleMonth(month) {
  var m = month;
  if (month.toString().length == 1) {
    m = "0" + month;
  }
  return m;
}

// 截取希尔顿欢朋
export function showName(val, type) {
  let $route = router.history.current
  let username = sessionStorage.username ? JSON.parse(sessionStorage.getItem('username')) : null
  let splitText = username == 'xiaoshou' ? "**" : ""
  if (splitText && val == '希 尔 顿 欢 朋 酒 店') {
    return '* * 酒 店'
  } else if ($route.meta.title == '万润看板' && type) {
    return showNameFunction(val, splitText)
    //  splitText ? showNameFunction(val, splitText) : val
  } else {
    return splitText ? showTop2Name(val, splitText) : showNameFunction(val, splitText)
  }

}

function showNameFunction(val, splitText) {
  if (val.indexOf('希尔顿欢朋酒') != -1) {
    return val ? val.split('希尔顿欢朋酒').join(splitText) : val
  } else {
    return val ? val.split('希尔顿欢朋').join(splitText) : val
  }
}
// 廣州**店
function showTop2Name(val, splitText) {
  return val ? `${val.substring(0,2)}${splitText}${val.substring(val.length-1,val.length)}` : val

}

let chinaMap = ["北京", "天津", "上海", "重庆", "河北", "河南", "云南", "辽宁", "黑龙江", "湖南", "安徽", "山东", "新疆", "江苏", "浙江", "江西", "湖北", "广西", "甘肃", "山西", "内蒙古", "陕西", "吉林", "福建", "贵州", "广东", "青海", "西藏", "四川", "宁夏", "海南", "台湾", "香港", "澳门", ]
// 中国地图各省份数据封装  所有省份
// export function getDataMap (list){
//     let arr=[],index=0
//     chinaMap.forEach(v=>{
//       let obj={
//         name: v,
//         value:0,
//         operation: 0,
//         toBeOpened: 0,
//         tryIndustry: 0,
//         underConstruction: 0,
//       }
//       if(index<list.length){
//         for (let i = 0; i < list.length; i++) {
//           if(list[i].provinceName.indexOf(v)!=-1){
//             obj.operation=list[i].operation
//             obj.toBeOpened=list[i].toBeOpened
//             obj.tryIndustry=list[i].tryIndustry
//             obj.underConstruction=list[i].underConstruction
//             index++
//           }
//         }
//       }
//       arr.push(obj)
//     })
//     return arr
// }
// 获取省份的信息（运营状态）
export function getDataMap(list) {
  let arr = [],
    index = 0
  if (index < list.length) {
    for (let i = 0; i < list.length; i++) {
      let obj = {
        value: 0,
        operation: 0,
        toBeOpened: 0,
        tryIndustry: 0,
        underConstruction: 0,
      }
      chinaMap.forEach(v => {
        if (list[i].provinceName.indexOf(v) != -1) {
          for (let key in obj) {
            if (key != 'value') {
              obj[key] = list[i][key]
            }
          }
          obj.name = v
          index++
          arr.push(obj)
        }
      })
    }
  }
  return arr
}
// 获取省份的信息（运营分店数 客房数 取电房间数）
export function getDataMap1(list) {
  let arr = [],
    index = 0
  if (index < list.length) {
    for (let i = 0; i < list.length; i++) {
      let obj = {
        value: 0,
        operation: 0,
        totalRoom: 0,
        electricRoom: 0
      }
      chinaMap.forEach(v => {
        if (list[i].provinceName.indexOf(v) != -1) {
          for (let key in obj) {
            if (key != 'value') {
              obj[key] = list[i][key]
            }
          }
          obj.name = v
          index++
          arr.push(obj)
        }
      })
    }
  }
  return arr
}




const regionType = {
  "华北区": ['110000', '120000', '140000', '130000', '150000'], //150000  内蒙古
  "东北区": ['230000', '220000', '210000', '150000'],
  "华东区": ['310000', '320000', '330000', '340000', '360000', '370000', '350000', '710000'],
  "华中区": ['410000', '420000', '430000'],
  "华南区": ['440000', '450000', '460000', '810000', '820000'],
  "西南区": ['500000', '510000', '520000', '530000', '540000'],
  "西北区": ['610000', '620000', '630000', '640000', '650000', '150000'],
};
// 根据区域返回省份列表
export function returnNewArea(name, regionData) {
  let areaArr = []
  regionData.forEach(v => {
    if (regionType[name].includes(v.value)) {
      areaArr.push(v)
    }
  })
  return areaArr
}



export function breadData(Adata) {
  //	console.(Adata)
  var optionBread = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      bottom: 10,
      left: 'center',
      // orient: 'vertical',
      itemWidth: 8, // 设置图例图形的宽
      itemHeight: 8,
      icon: "circle",
      data: ['未处理', '已处理', ]
    },
    series: [{
      name: '',
      type: 'pie',
      radius: ['40%', '60%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',
      },
      center: ['50%', '40%'],
      emphasis: {
        // itemStyle: {
        // 	shadowBlur: 1,
        // 	shadowOffsetX: 0,
        // 	shadowColor: 'rgba(0, 0, 0, 0.5)'
        // },
        label: {
          show: true,
          fontSize: '12',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        // show: false
        lineStyle: {},
        smooth: 0.2,
        length: 3,
        length2: 8
      },
      data: [{
          value: Adata.totalCount == 0 || Adata.totalCount ? Adata.totalCount : Adata.normalNumber,
          name: '已处理',
          itemStyle: {
            normal: {
              color: '#87ceeb'
            }
          }
        },
        {
          value: Adata.ingCount == 0 || Adata.ingCount ? Adata.ingCount : Adata.exceptionNumber,
          name: '未处理',
          itemStyle: {
            normal: {
              color: '#ff0000'
            }
          }
        },

      ],
      animationType: 'scale',
      animationEasing: 'elasticOut',
      animationDelay: function (idx) {
        return Math.random() * 200;
      }
    }]
  };
  return optionBread
}


// 判断是否在手机 或者电视
export function isPhone() {
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
}



export function showRoomType(val, roomTypeList) {
  let text = ''
  if (val != null && val != undefined) {
    try {
      roomTypeList.forEach(v => {
        if (val == v.value) {
          throw v.lable
        }
      })
    } catch (e) {
      text = e
    }
  }
  return text
}


//加法运算   
export function floatAdd(arg1, arg2) {
  var r1, r2, m;
  try {
    r1 = arg1.toString().split(".")[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split(".")[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
}
//减法运算   
export function floatSub(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split(".")[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2));
  //动态控制精度长度   
  n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

//乘法运算   
export function floatMul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();

  try {
    m += s1.split(".")[1].length
  } catch (e) {}
  try {
    m += s2.split(".")[1].length
  } catch (e) {}
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}


//除法运算 
export function floatp(arg1, arg2) {
  var t1 = 0,
    t2 = 0,
    r1, r2;
  try {
    t1 = arg1.toString().split(".")[1].length
  } catch (e) {}
  try {
    t2 = arg2.toString().split(".")[1].length
  } catch (e) {}
  r1 = Number(arg1.toString().replace(".", ""));
  r2 = Number(arg2.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}

export function downRes(res, name) {
  var blob = new Blob([res.data], {
    type: "application/vnd.ms-excel",
  });
  var downloadElement = document.createElement("a");
  var href = window.URL.createObjectURL(blob); // 创建下载的链接
  downloadElement.href = href;
  downloadElement.download = `${name}.xlsx`; // 下载后文件名
  document.body.appendChild(downloadElement);
  downloadElement.click(); // 点击下载
  document.body.removeChild(downloadElement); // 下载完成移除元素
  window.URL.revokeObjectURL(href); // 释放掉blob对象
}